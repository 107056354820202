<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <mds-layout-grid class="product-details-section">
        <mds-row v-if="flipContent">
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="6"
            class="product-details-section-row-flippedimg">
            <img :src="imagePath" />
          </mds-col>
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="5" 
            class="product-details-section-row-description"
            >
            <div class="item-title" v-html="title"></div>
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              class="display-content"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
            <mds-button
              class="product-key-benefits-action-btn product-key-benefits-trial"
              variation="primary"
              size="large"
              target="_blank"
              @click="toggleCTA(findOutMoreLink)"
            >
              <div> 
                <span v-if="findOutMoreLink ==='/products/report-stream'">Request a Demo</span>
                <span v-else >Request a Demo</span>
              </div>
            </mds-button>
            <mds-button
              class="product-key-benefits-action-btn"
              variation="secondary"
              size="large"
              target="_blank"
              :href="findOutMoreLink"
            >
              Find Out More 
            </mds-button>
          </mds-col>
        </mds-row>
        <mds-row v-else>
          <mds-col
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="5"
            class="product-details-section-row-description">
            <div class="item-title" v-html="title"></div>
            <div class="item-intro" v-html="introduction"></div>
            <br />
            <div
              v-for="(item, index) in keyPoints"
              class="display-content"
              :key="`key-points-${index}`"
            >
              <ul>
                <li>{{ item }}</li>
              </ul>
            </div>
            <mds-button
              class="product-key-benefits-action-btn  product-key-benefits-trial"
              variation="primary"
              size="large"
              target="_blank"
              @click="toggleCTA(findOutMoreLink)"
            >
              Request a Demo 
            </mds-button>
            <mds-button
              class="product-key-benefits-action-btn"
              variation="secondary"
              size="large"
              target="_blank"
              :href="findOutMoreLink"
            >
              Find Out More
            </mds-button>
          </mds-col>
          <mds-col 
            :cols-at-s="11"
            :cols-at-m="11"
            :cols-at-l="6"
            :cols-at-xl="6"
            class="product-details-section-row-img">
            <img :src="imagePath" />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
    <mds-modal
      v-model="toggle"
      class="mcia-get-started-form-model"
      aria-label="Default Modal"
      size="medium"
      :width="'900px'"
    >
      <get-started-form
        form-id="get-started-form-model"
        @exit="getStartedFormToggled"
        @toggleNotification="getStartedThankYouToggled"
      />
    </mds-modal>
  </div>
</template>

<script>

import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'PlatformItem',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsModal,
    GetStartedForm,
  },
  data() {
    return {
      toggle: false,
      showNotification: false,
      flipContent: false,
      fromPlatformPage: true,
      toggleModal:false,
    };
  },
  computed: {
    imagePath() {
      return require('../../assets/' + this.imageFileName);
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    ...mapActions({
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
    getStartedFormToggled() {
      this.toggle = !this.toggle; 
    },
    getStartedThankYouToggled(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 5000);
      }
    },
    getStartedToggleNotification(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 1000);
      }
    },
    handleWindowResize() {
      if (window.outerWidth <= 768) {
        this.flipContent = false;
      } else {
        this.flipContent = this.flipped;
      }
    },
    toggleCTA(item) {
      this.toggle = !this.toggle;
      switch (item) {
        case "/cre-analytics/surveillance":
          this.saveSelectedProducts(['cmbs_intel_surv']);
          break;
        case "/cre-analytics/lead-gen":
          this.saveSelectedProducts(['lead_gen']);
          break;
        case "/cre-analytics/cmbsdata":
          this.saveSelectedProducts(['cmbs_data']);
          break;
        case "/products/report-stream":
          this.saveSelectedProducts(['report_stream']);
          break;
      }
    }
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    introduction: {
      type: String,
      default: '',
      required: true,
    },
    keyPoints: {
      type: Array,
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: true,
    },
    flipped: {
      type: Boolean,
      default: false,
      required: true
    },
    findOutMoreLink: {
      type: String,
      default: '',
      required: true,
    }
  }
};
</script>

<style scoped lang="scss">
.product-details-section {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  &-row {
    &-description {
      // margin-right: calc((#{$mds-space-6-x} * 2) - $mds-space-quarter-x);
    }
    &-img {
      display: flex;
      justify-content: right;
      margin-right: 97px;
      width: 430px;
      height: 357px;
      // max-width: -webkit-fill-available;
    }
    &-flippedimg {
      display: flex;
      justify-content: left;
      width: 430px;
      height: 357px;
    }
  }
}
.mcia-outer-conatiner {
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 95px;
    @media (max-width: $mds-breakpoint-m) {
      margin-bottom: 40px;
    }
  }
}

.item-title{
  @include mds-eyebrow-heading();
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.167em;
  padding-bottom: calc(#{$mds-space-1-and-a-half-x} + #{$mds-space-quarter-x});
}

.item-intro{
  @include mds-level-2-heading($bold: true);
  width: 590px;
  @media (max-width: $mds-breakpoint-m) {
    display: contents;
  }
}
ul {
  width: 580px;
  @include mds-unordered-list(large);
}

ul li {
  &::before{
    color: $mds-text-color-primary;
  }
}
.product-key-benefits-action-btn {
  margin-top: 35px;
}
.product-key-benefits-trial {
  margin-right: 10px;
}
.product-details-section-row-img {
  @media (max-width: $mds-breakpoint-m) {
    justify-content: start;
    padding-top: 12px;
    margin-right: 0px;
  }
}
.display-content {
  display: flex;
}
img {
  max-width: -webkit-fill-available;
  @media (max-width: $mds-breakpoint-m) {
    padding-bottom: 12px;
  }
}
</style>
